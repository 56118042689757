import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class Cycling extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Cycling" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className='pt-5'>Want to go cycling in the Lake District?</h1>
            <p>The Lake District is one of the most scenic and beautiful places in the UK to go cycling in the UK. That said, it is also one of the most challenging due to the amount of tough climbs and fast-changing weather.</p>
            <h5>Here are some useful resources:</h5>
            <ul>
            <li><a href="https://www.countrylaneslakedistrict.co.uk/">Country Lanes Cycles (road and mountain bike hire) - Windermere</a></li>
            <li><a href="http://www.lakedistrictbikehire.co.uk/">Lake District Bike Hire (mountain bike hire) - Windermere</a></li>
            <li><a href="https://total-adventure.co.uk/">Total Adventure Bike Hire (mountain and e-bike) - Windermere</a></li>
            <li><a href="https://lakedistrictbikes.com/">Lake District Bikes (road, mountain, gravel, e-bike hire) - Ulverston</a></li>
            <li><a href="https://www.keswickbikes.co.uk/">Keswick Bikes (mountain bike and e-bike hire) - Keswick</a></li>
            <li><a href="https://www.bike-treks.co.uk/">Biketreks (mountain and e-bikes hire) - Ambleside</a></li>
            <li><a href="https://www.wheelbase.co.uk/">Wheelbase (fat bikes and e-mountain bike hire) - Staveley</a></li>
            </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Cycling

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
